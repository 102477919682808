$background-color: #d9cb9e;
$text-color: #374140;
$accent-color: #e45354;
$dark-color: #374140;
$light-color: #bdc3c7;

html,
body,
body > .wrapper {
  min-width: 100vw;
  min-height: 100vh;
  font-family: monospace;
  background: $background-color;
  color: $text-color;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  @media (prefers-color-scheme: dark) {
    background: $dark-color;
    color: $light-color;
  }
}

i {
  font-family: inherit;
}

.e-mail:before {
  content: attr(data-user) "\0040"attr(data-website);
  unicode-bidi: bidi-override;
  direction: ltr;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    align-items: flex-start;

    & > .container {
      padding-top: 100px;
    }
  }
}

.tagline {
  text-align: center;
  max-width: 400px;

  h2 {
    margin-top: -1rem;
  }
}

.avatar-container {
  .avatar {
    position: relative;
    z-index: 100;
    img {
      display: block;
      margin: 0 auto;
      max-width: 300px;

      @media screen and (max-width: 600px) {
        max-width: 250px;
      }
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 0 auto;

  .link {
    $height: 48px;
    display: block;
    position: relative;
    width: 100%;
    height: $height;
    box-sizing: border-box;
    border: 3px solid $dark-color;
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    background-color: transparent;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      cursor: pointer;
    }

    @media (prefers-color-scheme: dark) {
      border-color: lighten($accent-color, 10%);
    }

    a,
    span,
    i,
    svg {
      color: $dark-color;

      @media (prefers-color-scheme: dark) {
        color: $light-color;
      }
    }

    a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      line-height: $height - 6px;
      font-size: 1rem;
      text-decoration: none;
    }

    i,
    svg {
      position: absolute;
      left: 1rem;
      font-size: 2rem;
      top: 50%;
      bottom: 0;
      line-height: $height - 6px;
      transform: translateY(-50%);
    }

    &:hover {
      color: $dark-color;
      background-color: darken($dark-color, 10%);
      border-color: lighten($dark-color, 10%);

      i,
      a,
      svg,
      span {
        color: $accent-color;
      }

      @media (prefers-color-scheme: dark) {
        color: $accent-color;
        background-color: darken($dark-color, 10%);
        border-color: lighten($dark-color, 10%);
      }
    }
  }
}

.circles {
  position: fixed;
  top: 0;
  left: 0;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba($accent-color, 0.2);
  bottom: -300px;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      $size: random(120);
      $delay: random(20);
      $speed: random(50) + 10;
      left: #{random(100) + "%"};
      width: #{$size}px;
      height: #{$size}px;
      background: rgba($accent-color, random(6) / 10);
      animation: animate-#{$i} #{$speed}s ease-in-out infinite;
      animation-delay: $delay;

      @media screen and (max-width: 600px) {
        @if ($i > 25) {
          display: none;
        }
      }

      @keyframes animate-#{$i} {
        0% {
          transform: translateY(0) rotate(random(360));
          opacity: 1;
          border-radius: 0;
        }
        100% {
          transform: translateY(-110vh) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
        }
      }
    }
  }
}
